/* src/styles/global.css */

/* Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', sans-serif;
    background-color: #202124;
    color: #fff;
    margin: 0;
  }
  
  a {
    color: #1DA1F2; /* Twitter Blue */
    text-decoration: none;
    transition: color 0.3s;
  }
  
  a:hover {
    color: #1A91DA; /* Darker Shade */
  }
  
  li,
  ul {
    list-style: none;
  }
  
  /* Global Buttons */
  button {
    background-color: #1DA1F2; /* Twitter Blue */
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s ease, color 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  button:hover {
    background-color: #1A91DA; /* Darker Shade */
    color: #fff;
  }
  
  /* Main Container */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 20px;
  }
  
  /* Content Wrapper */
  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
  }
  
  /* Twitter Container */
  .twitter-container {
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    min-width: 300px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  }
  
  .twitter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .twitter-inline {
    display: flex;
    align-items: center;
  }
  
  .twitter-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-right: 15px;
  }
  
  .twitter-text h3 {
    margin: 0;
    font-size: 18px;
    color: #fff;
  }
  
  .x-back,
  .x-post {
    background-color: #000;
    color: #1DA1F2; /* Twitter Blue */
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  .x-post {
    background-color: #1DA1F2; /* Twitter Blue */
    color: #fff;
  }
  
  .x-back:hover,
  .x-post:hover {
    background-color: #1A91DA; /* Darker Shade */
    color: #fff;
  }
  
  /* Form Container */
  .form-container {
    background-color: #1a1a1a;
    padding: 30px 40px;
    border-radius: 15px;
    width: 55%;
    min-width: 300px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    text-align: center;
    transition: transform 0.2s;
  }
  
  .form-container:hover {
    transform: translateY(-5px);
  }
  
  .form-container h2 {
    margin-bottom: 25px;
    color: #1DA1F2; /* Twitter Blue */
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
  }
  
  .form-container input {
    width: 100%;
    padding: 12px 15px;
    margin: 12px 0;
    border: 1px solid #555;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 1rem;
    background-color: #2a2a2a;
    color: #fff;
    transition: border 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  .form-container input:focus {
    border: 1px solid #1DA1F2; /* Twitter Blue */
  }
  
  .form-container button[type='submit'] {
    background-color: #1DA1F2; /* Twitter Blue */
    color: #fff;
    font-size: 1rem;
    margin-bottom: 25px;
    margin-top: 20px;
    padding: 12px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  .form-container button[type='submit']:hover {
    background-color: #1A91DA; /* Darker Shade */
    color: #fff;
  }
  
  .captcha-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    margin: 10px 0;
    font-family: 'Poppins', sans-serif;
  }
  
  .captcha-question {
    font-size: 1.2rem;
    margin-right: 10px;
    color: #1DA1F2; /* Twitter Blue */
  }
  
  .captcha-container button#reroll {
    width: 50px;
    height: 50px;
    background-color: #1DA1F2; /* Twitter Blue */
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s;
    margin-right: 10px;
  }
  
  .captcha-container button#reroll:hover {
    background-color: #1A91DA; /* Darker Shade */
    color: #fff;
  }
  
  .captcha-container input {
    width: 100px;
    padding: 10px;
    margin-left: 10px;
    border: 1px solid #555;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 1rem;
    background-color: #2a2a2a;
    color: #fff;
    transition: border 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  .captcha-container input:focus {
    border: 1px solid #1DA1F2; /* Twitter Blue */
  }
  
  .message-container {
    margin: 15px 0;
    padding: 12px 20px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
  }
  
  .success-message {
    background-color: rgba(0, 255, 0, 0.2);
    color: green;
  }
  
  .error-message {
    background-color: rgba(255, 0, 0, 0.2);
    color: red;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .content-wrapper {
      flex-direction: column;
      align-items: center;
    }
  
    .twitter-container,
    .form-container {
      width: 90%;
    }
  
    .captcha-container img {
      width: 120px;
    }
  }
  
  @media (max-width: 768px) {
    .form-container h2 {
      font-size: 1.8rem;
    }
  
    .form-container input,
    .form-container button {
      font-size: 0.9rem;
    }
  
    .captcha-container button#reroll {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
  
    .twitter-avatar {
      width: 40px;
      height: 40px;
    }
  
    .twitter-text h3 {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .form-container input {
      padding: 8px 10px;
    }
  
    .form-container button {
      padding: 8px 12px;
    }
  
    .captcha-container {
      flex-direction: column;
    }
  
    .captcha-container button#reroll {
      width: 35px;
      height: 35px;
      font-size: 14px;
      margin: 10px 0;
    }
  
    .captcha-container input {
      width: 80%;
      margin: 0;
    }
  
    .x-back,
    .x-post {
      font-size: 14px;
      padding: 6px 10px;
    }
  
    .twitter-avatar {
      width: 35px;
      height: 35px;
    }
  
    .twitter-text h3 {
      font-size: 14px;
    }
  }
  