/* src/styles/dashboard.css */

/* Hide the hamburger menu checkbox */
.hamburger_menu input[type="checkbox"] {
    display: none !important;
}

/* Navbar */
.navbar {
    box-shadow: 0 1px 4px rgba(21, 23, 24, 0.002);
    width: 100%;
    background-color: rgba(32, 33, 36, 1);
}

/* Adjust to avoid conflict with .container in global.css */
.nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    padding: 0 20px;
    position: relative;
}

/* Logo */
.logo {
    color: #0e2431;
    font-size: 1.2rem;
    position: absolute;
    right: 15px;
    top: 16px;
}

/* Hamburger Menu */
.hamburger_menu {
    position: relative;
    display: flex;
    align-items: center;
}

.menu__btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 26px;
    justify-content: space-between;
    width: 26px;
    z-index: 1001;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
    background-color: #1DA1F2; /* Changed to Twitter blue */
    display: block;
    height: 2px;
    transition: all 0.25s ease;
    width: 100%;
    position: relative;
}

.menu__btn > span::before {
    content: "";
    position: absolute;
    top: -8px;
}

.menu__btn > span::after {
    content: "";
    position: absolute;
    top: 8px;
}

/* Menu Box */
.menu__box {
    background-color: #1d1d1d;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    height: 100%;
    left: -300px;
    list-style: none;
    margin: 0;
    padding: 80px 0;
    position: fixed;
    top: 0;
    width: 300px;
    z-index: 1000;
    transition: left 0.25s ease;
}

/* Transformations of hamburger button when menu is open */
#menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
}

#menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
}

#menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
}

#menu__toggle:checked ~ .menu__box {
    left: 0 !important;
}

/* Menu Items */
.menu__item {
    display: block;
    padding: 12px 30px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #e8e6e3;
    text-decoration: none;
    transition: background-color 0.25s ease, color 0.25s ease;
}

.menu__item:hover {
    background-color: #2c2c2c;
    color: #1DA1F2; /* Changed to Twitter blue */
}

/* Bottom Buttons */
.bottom-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 800;
}

.buyNow {
    background-color: #19b86e;
    padding: 10px 20px;
    border-radius: 50px;
    box-shadow: 0 2px 8px 0 #63636333;
    color: #fff;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    transition: background-color 0.3s ease;
}

.buyNow:hover {
    background-color: #138f5e;
}

/* Info Container */
.info-container {
    margin: 0 20px;
    margin-top: 20px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 10px;
    position: relative;
    transition: margin-left 0.25s ease;
}

/* Shift content when menu is open */
.info-container.menu-open {
    margin-left: 300px;
}

/* Remove scrollbars */
.info-container::-webkit-scrollbar {
    display: none;
}

.info-container {
    scrollbar-width: none;
}

/* Profile Data */
.profileData {
    padding: 0 30px;
    margin-bottom: 20px;
}

.profileData h3,
.profileData h4 {
    color: #e8e6e3;
    margin-bottom: 10px;
}

.profileData h4 {
    margin-bottom: 4px;
}

.profileData button {
    background-color: #1DA1F2; /* Changed to Twitter blue */
    border: none;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 25px;
    margin-top: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profileData button:hover {
    background-color: #1A91DA; /* Darker blue for hover effect */
}

/* Card */
.card {
    background-color: #1d1d1d;
    padding: 20px;
    border: 2px dotted #e8e6e3;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

.card h3 {
    color: #fff;
}

.card .center {
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 10px;
}

.card .color {
    color: #1DA1F2; /* Changed to Twitter blue */
}

.card .green {
    color: #0483aa;
}

.card input[type="text"] {
    background-color: #202324;
    border: 1px solid #141414;
    color: #fff;
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
}

.copyButton {
    background-color: #23da85;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
    opacity: 0.9;
    padding: 8px;
    width: 100%;
    border-radius: 5px;
    transition: opacity 0.3s ease;
}

.copyButton:hover {
    opacity: 1;
}

/* General Buttons */
.button, .tier, .access, .invite, button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-family: Arial, sans-serif;
    margin: 10px 0;
    border: none;
    cursor: pointer;
}

.button:hover, .tier:hover, .access:hover, .invite:hover, button:hover {
    background-color: #218838;
}

/* Tier Card */
.tierCard {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 12px;
    border: 2px dotted #e8e6e3;
    margin-bottom: 20px;
    text-align: center;
}

/* Additional Classes */
.centermid {
    text-align: center;
}

.green {
    color: #0483aa;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .info-container {
        margin: 0 20px;
    }
}

@media (max-width: 768px) {
    .navbar {
        height: 60px;
    }

    .nav-container {
        height: 60px;
    }

    .menu__box {
        width: 80%;
    }

    .bottom-buttons {
        bottom: 20px;
        right: 20px;
        margin: 20px;
    }

    .card {
        padding: 15px;
    }

    .tierCard {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .info-container {
        margin: 0 20px;
    }

    .bottom-buttons {
        bottom: 10px;
        right: 10px;
        margin: 10px;
    }

    .card {
        padding: 10px;
    }

    .tierCard {
        padding: 10px;
    }
}

/* Styles for Invites.js */

/* Invites Page */
.invites-page {
    padding: 20px;
    background-color: #121212;
    min-height: 100vh;
    color: #e8e6e3;
}

/* Invites Container */
.invites-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Invite Card */
.invite-card {
    background-color: #1e1e1e;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #333;
    transition: background-color 0.3s ease, border 0.3s ease;
}

.invite-card:hover {
    background-color: #2c2c2c;
    border-color: #555;
}

/* Tier Unlocked */
.tier-unlocked {
    margin-top: 10px;
    color: #23da85;
    font-weight: bold;
}

/* Color Span */
.color {
    color: #1DA1F2; /* Changed to Twitter blue */
}

/* Styles for Preview.js */

/* Preview Page */
.preview-page {
    padding: 20px;
    background-color: #121212;
    min-height: 100vh;
    color: #e8e6e3;
}

/* Card */
.card {
    background-color: #1d1d1d;
    padding: 20px;
    border: 2px dotted #e8e6e3;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

.centermid {
    text-align: center;
}

/* Preview Container */
.preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Video Card */
.video-card {
    background-color: #1e1e1e;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #333;
    width: calc(50% - 40px); /* Adjust as necessary */
    box-sizing: border-box;
    text-align: center;
}

.video-card video {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.video-card p {
    margin-top: 10px;
    color: #e8e6e3;
}

@media (max-width: 768px) {
    .video-card {
        width: 100%;
    }
}
